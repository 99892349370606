import { Listbox } from '@headlessui/react';
import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import ContentfulRichText from '../components/contentfulRichText';
import Layout from '../components/layout';
import { Seo } from '../components/seo';
import IconDownBlack from '../images/icon-down-black.svg';
import IconDownWhite from '../images/icon-down-white.svg';

const ContactForm = ({ status, message, onValidated, confirmation }) => {
  const reasons = [
    '',
    'Art',
    'See',
    'Schedule An Appointment',
    'General Inquiry',
  ];

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [formReason, setFormReason] = useState(reasons[0]);
  const [subject, setSubject] = useState('');
  const [formMessage, setFormMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    name &&
      phone &&
      email &&
      formReason &&
      subject &&
      formMessage &&
      email.indexOf('@') > -1 &&
      onValidated({
        MERGE1: name,
        MERGE4: phone,
        MERGE0: email,
        MERGE2: formReason,
        MERGE3: subject,
        MERGE5: formMessage,
      });
    /*console.log({
      MERGE1: name,
      MERGE4: phone,
      MERGE0: email,
      MERGE2: formReason,
      MERGE3: subject,
      MERGE5: formMessage,
    })*/
  };

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status]);

  const clearFields = () => {
    setName('');
    setPhone('');
    setEmail('');
    setFormReason('');
    setSubject('');
    setFormMessage('');
  };

  return (
    <form>
      <div className="grid md:grid-cols-2 gap-x-8 gap-y-12">
        <div className="col-span-2 md:col-span-1 form-field-set">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            className="form-field"
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-span-2 md:col-span-1 form-field-set">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            name="phone"
            className="form-field"
            required={true}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="col-span-2 md:col-span-1 form-field-set">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            className="form-field"
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-span-2 md:col-span-1 form-field-set">
          <label htmlFor="reason">Reason</label>
          <Listbox value={formReason} onChange={setFormReason}>
            <div className="relative w-full">
              <Listbox.Button className="flex w-full">
                <img
                  src={IconDownBlack}
                  alt="Select Reason"
                  className="h-4 mx-4"
                />
                {formReason}
              </Listbox.Button>
              <Listbox.Options>
                <div className="absolute top-0 inset-x-0 bg-white shadow cursor-pointer">
                  <div className="flex">
                    <img
                      src={IconDownBlack}
                      alt="Select Reason"
                      className="h-4 mx-4 translate-y"
                    />

                    <div>
                      {reasons.map((reason, index) => (
                        <Listbox.Option key={index} value={reason}>
                          {reason}
                        </Listbox.Option>
                      ))}
                    </div>
                  </div>
                </div>
              </Listbox.Options>
            </div>
          </Listbox>
          {/*<Menu>
            <Menu.Button>
              <img
                src={IconDownBlack}
                alt="Select Reason"
                className="h-4 mr-2"
              />
              {formReason}
            </Menu.Button>
            <Menu.Items>
              {reasons.map((reason, index) => (
                <Menu.Item
                  key={index}
                >
                  <button onClick={() => setFormReason(reason)}>{reason}</button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>*/}
        </div>

        <div className="col-span-2 form-field-set mb-4">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            className="form-field"
            required={true}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div className="col-span-2 form-field-set">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            rows="4"
            className="form-field"
            required={true}
            onChange={(e) => setFormMessage(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-16 text-center">
        {status === 'sending' ? (
          <>Sending...</>
        ) : (
          <button
            onClick={handleSubmit}
            className="btn-solid-dark px-4 py-1 flex items-center font-bold mx-auto cursor-pointer"
          >
            <img
              src={IconDownWhite}
              alt="Send"
              className="h-4 mr-2 rotate-90"
            />
            Send
          </button>
        )}

        {status === 'error' && (
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </form>
  );
};

const HelloPage = ({ data }) => {
  const postUrl = `https://gmail.us21.list-manage.com/subscribe/post?u=bd8cacf8477c77e000e5d4e29&id=44b2de80b5`;

  return (
    <Layout>
      <main className="py-16 px-8">
        <header className="container mx-auto text-center py-16 typography">
          <ContentfulRichText richText={data.contentfulContactPage.content} />
        </header>

        <section className="container mx-auto">
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <ContactForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
                confirmation={data.contentfulContactPage.confirmation}
              />
            )}
          />
        </section>
      </main>
    </Layout>
  );
};

export default HelloPage;

export const Head = ({ data }) => (
  <Seo
    title={data.contentfulContactPage.meta.title}
    description={data.contentfulContactPage.meta.description}
  />
);

export const query = graphql`
  {
    contentfulContactPage(contentful_id: { eq: "70U1vBEHVmh5lP6oZ7Df89" }) {
      meta {
        title
        description
      }
      content {
        raw
      }
      confirmation {
        raw
      }
    }
  }
`;
